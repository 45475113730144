import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { JsonpModule } from '@angular/http';

import { LoginoneComponent } from './loginone/loginone.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LockScreenComponent } from './lockscreen/lockscreen.component';
import { RegisterClubComponent } from './register-club/club.component';

import { SessionRoutes } from './session.routing';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { EmailverifyComponent } from './emailverify/emailverify.component';
@NgModule({
   imports: [
      CommonModule,
      MatFormFieldModule, MatStepperModule, MatButtonModule, MatIconModule, MatInputModule, MatDatepickerModule, MatRadioModule,
      MatCheckboxModule, EditorModule, CalendarModule, JsonpModule, MatSelectModule,
      FormsModule,
      TranslateModule,
      ReactiveFormsModule,
      ToastrModule.forRoot(),
      RouterModule.forChild(SessionRoutes)
   ],
   declarations: [
      RegisterClubComponent,
      LoginoneComponent,
      RegisterComponent,
      ForgotPasswordComponent,
      LockScreenComponent,
      RegisterSuccessComponent,
      EmailverifyComponent
   ]
})

export class SessionDemoModule { }
