import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { ViewListComponent } from '../../widgets-component/pop-up/view-list/view-list.component';
import { FormComponent } from '../../widgets-component/pop-up/report/form.component';
import { NewUserComponent } from '../../widgets-component/pop-up/newUser/newUser.component';

@Injectable({
	providedIn: 'root'
})

export class CoreService {

	public rtlShowStatus : boolean = false;
	constructor( private http : HttpClient,
				 private modalService: NgbModal ) { }

	/**
	  * getCourses method is used to get the courses data from json file.
	  */
	getCourses() {
		return this.http.get('assets/data/courses.json').map(response => response);
	}

	
	/**
	  * viewList function is used to open View Dialog Component. 
	  */
	viewList(data,message,titleMessage) {
		const modalRef = this.modalService.open(ViewListComponent);
		modalRef.componentInstance.data = data;
		modalRef.componentInstance.title = message;
		modalRef.componentInstance.titleMessage = titleMessage;
	}

	newUser() {
		const modalRef = this.modalService.open(NewUserComponent);
	}

	formList(data,message,titleMessage) {
		const modalRef = this.modalService.open(FormComponent);
		modalRef.componentInstance.data = data;
		modalRef.componentInstance.title = message;
		modalRef.componentInstance.titleMessage = titleMessage;
	}
	
}
