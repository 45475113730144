import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ClubService } from './club.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { Jsonp } from '@angular/http';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from "rxjs/operators";
import { AuthService } from '../../service/auth/auth.service';
import { Router } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
@Component({
  selector: 'app-register-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss'],
  providers: [ClubService, {
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class RegisterClubComponent implements OnInit {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;
  seventhFormGroup: FormGroup;
  consent = false;
  gender = ['Male', 'Female'];
  meds = ['Yes', 'No'];
  userData = [];
  docId: any;
  newsLetter = false;
  userid: any;
  selectedImage: any = null;
  images: any[] = [];

  constructor(private _formBuilder: FormBuilder,
    @Inject(AngularFireStorage) private storage: AngularFireStorage,
    public translate: TranslateService, private router: Router,
    private pageTitleService: PageTitleService,
    private route: ActivatedRoute,
    private jsonp: Jsonp,
    private authService: AuthService,
    private service: ClubService,
    public toastService: ToastrService,
    private firestore: AngularFirestore) {
    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      registrationNo: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['East Midlands', Validators.required],
      country: ['', Validators.required],
      postcode: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      landlineNo: ['', Validators.required],
      mobileNo: ['', Validators.required],
      logoUrl: [''],
      description: ['', Validators.required],
      notificationDays: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      otherContactDetails: this._formBuilder.array([
        this.getOtherContactForm()
      ])
    });
    this.thirdFormGroup = this._formBuilder.group({
      personName: ['', Validators.required],
      association: ['', Validators.required],
      clubName: ['', Validators.required],
      secretary: ['', Validators.required],
      associationSecretary: ['', Validators.required],
    });
    this.fourthFormGroup = this._formBuilder.group({
      planDetails: this._formBuilder.array([
        this.getPlanDetailsForm()
      ])
    });
    this.fifthFormGroup = this._formBuilder.group({
      serviceProvide: this._formBuilder.array([
        this.getServiceForm()
      ])
    });
    this.sixthFormGroup = this._formBuilder.group({
      groupType: this._formBuilder.array([
        this.getGroupTypeForm()
      ])
    });
    this.seventhFormGroup = this._formBuilder.group({
      website: [''],
      facebook: [''],
      twitter: [''],
      instagram: [''],
    });
  }

  ngOnInit() {
    this.translate.get('Add New Club Details').subscribe((res: string) => {
      this.pageTitleService.setTitle(res);
    });
    if (this.route.snapshot.paramMap.get('id'))
      this.getClubData();
  }

  getOtherContactForm(): FormGroup {
    return this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      postcode: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contactNo1: ['', Validators.required],
      contactNo2: [''],
      sex: ['', Validators.required],
      dob: ['', Validators.required],
      imageUrl: [''],
    });
  }

  addNewInputField(): void {
    const control = <FormArray>this.secondFormGroup.controls.otherContactDetails;
    control.push(this.getOtherContactForm());
  }

  removeInputField(i: number): void {
    const control = <FormArray>this.secondFormGroup.controls.otherContactDetails;
    control.removeAt(i);
  }

  getPlanDetailsForm(): FormGroup {
    return this._formBuilder.group({
      plan: ['', Validators.required],
      type: ['session', Validators.required],
      price: ['', Validators.required],
      currency: ['GBP', Validators.required]
    });
  }

  getControlsSecondFormGroup() {
    return (this.secondFormGroup.controls.otherContactDetails as FormArray).controls;
  }

  getControlsfourthFormGroup() {
    return (this.fourthFormGroup.controls.planDetails as FormArray).controls;
  }

  getControlsfifthFormGroup() {
    return (this.fifthFormGroup.controls.serviceProvide as FormArray).controls;
  }

  getControlssixthFormGroup() {
    return (this.sixthFormGroup.controls.groupType as FormArray).controls;
  }

  addPlanDetailsField(): void {
    const control = <FormArray>this.fourthFormGroup.controls.planDetails;
    control.push(this.getPlanDetailsForm());
  }

  removePlanDetailsField(i: number): void {
    const control = <FormArray>this.fourthFormGroup.controls.planDetails;
    control.removeAt(i);
  }

  getServiceForm(): FormGroup {
    return this._formBuilder.group({
      name: ['', Validators.required],
    });
  }

  addServiceField(): void {
    const control = <FormArray>this.fifthFormGroup.controls.serviceProvide;
    control.push(this.getServiceForm());
  }

  removeServiceField(i: number): void {
    const control = <FormArray>this.fifthFormGroup.controls.serviceProvide;
    control.removeAt(i);
  }

  getGroupTypeForm(): FormGroup {
    return this._formBuilder.group({
      name: ['', Validators.required],
    });
  }

  addGroupTypeField(): void {
    const control = <FormArray>this.sixthFormGroup.controls.groupType;
    control.push(this.getGroupTypeForm());
  }

  removeGroupTypeField(i: number): void {
    const control = <FormArray>this.sixthFormGroup.controls.groupType;
    control.removeAt(i);
  }

  showPreview(event: any, type, index) {
    if (event.target.files && event.target.files[0]) {
      let imageData = {
        file: event.target.files[0],
        type: type,
        index: index
      }
      this.images.push(imageData);
      console.log(this.images)
    }
  }

  saveImageData(id, userId) {
    this.images.forEach(e => {
      const fileRef = this.storage.ref(id + e.type + e.index);
      this.storage.upload(id + e.type + e.index, e.file).snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            if (e.type == 'club') {
              let form = this.firstFormGroup.value;
              form.logoUrl = url;
              this.firestore.doc('clubs/' + id).update({
                clubDetails: form
              })
              if (userId != "")
                this.userUpdate(url, id, userId)
            } else {
              let form = this.secondFormGroup.value;
              form.otherContactDetails[e.index].imageUrl = url;
              this.firestore.doc('clubs/' + id).update({
                contactDetails: form
              })
            }
          })
        })
      ).subscribe();
    });
  }

  getClubData() {
    this.firestore.collection('clubs').doc(this.route.snapshot.paramMap.get('id')).get()
      .subscribe(snapshot => {
        this.docId = snapshot.id;
        this.userData.push(snapshot.data());
        this.firstFormGroup.setValue(this.userData[0].clubDetails);
        for (let index = 0; index < this.userData[0].contactDetails.otherContactDetails.length; index++) {
          this.userData[0].contactDetails.otherContactDetails[index].dob = new Date(this.userData[0].contactDetails.otherContactDetails[index].dob.seconds * 1000);
        }
        for (let index = 1; index < this.userData[0].contactDetails.otherContactDetails.length; index++) {
          this.addNewInputField();
        }
        this.secondFormGroup.patchValue(this.userData[0].contactDetails)
        this.thirdFormGroup.setValue(this.userData[0].englandClubFormDetails)
        for (let index = 1; index < this.userData[0].planDetails.planDetails.length; index++) {
          this.addPlanDetailsField();
        }
        this.fourthFormGroup.setValue(this.userData[0].planDetails)
        for (let index = 1; index < this.userData[0].services.serviceProvide.length; index++) {
          this.addServiceField();
        }
        this.fifthFormGroup.setValue(this.userData[0].services)
        this.sixthFormGroup.setValue(this.userData[0].groupType)
        this.seventhFormGroup.setValue(this.userData[0].socialMedia)
      });
  }

  async submit() {
    if (this.userData.length == 0) {
      let clubData = this.firstFormGroup.value;
      let user = await this.authService.clubSignUp(clubData.email, clubData.name);
      if (user.length > 0) {
        this.service.addClub(this.firstFormGroup.value, this.secondFormGroup.value, this.thirdFormGroup.value,
          this.fourthFormGroup.value, this.fifthFormGroup.value, this.sixthFormGroup.value, this.seventhFormGroup.value)
          .then(
            res => {
              this.saveImageData(res.id, user[0].data.uid);
              this.userUpdate("", res.id, user[0].data.uid)
              this.clubUpdate(res.id, user[0].password, user[0].data.uid);
              this.walletUpdate(res.id, user[0].data.uid)
              this.toastService.success('Club added successfully', 'Success');
              this.router.navigate(['/session/register-success/', user[0].data.uid]);
            }
          )
      }
    } else {
      this.service.updateClub(this.firstFormGroup.value, this.secondFormGroup.value, this.thirdFormGroup.value,
        this.fourthFormGroup.value, this.fifthFormGroup.value, this.sixthFormGroup.value, this.seventhFormGroup.value, this.route.snapshot.paramMap.get('id'))
        .then(
          res => {
            this.saveImageData(this.route.snapshot.paramMap.get('id'), "")
            this.toastService.success('Details successfully updated!', 'Success');
          }
        )
    }
  }

  clubUpdate(clubId, password, userId) {
    this.firestore.doc('clubs/' + clubId).update({
      password: password,
      userId: userId
    })
  }

  userUpdate(iamge, clubId, userId) {
    this.firestore.doc('users/' + userId).update({
      photoURL: iamge,
      clubId: clubId
    })
  }

  walletUpdate(clubId, userId) {
    this.firestore.collection('wallet', ref => ref.where('uid', '==', userId)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          this.firestore.doc('wallet/' + doc.id).update({
            clubId: clubId,
          })
        });
      });
  }
}
