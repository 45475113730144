import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmbedVideo } from 'ngx-embed-video';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { ViewListComponent } from './pop-up/view-list/view-list.component';
import { FormComponent } from './pop-up/report/form.component';
import { NewUserComponent } from './pop-up/newUser/newUser.component';
import { TableModule } from 'primeng/table';
import { EditorModule } from 'primeng/editor';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
	imports: [
		CommonModule,
		EmbedVideo.forRoot(),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		TableModule,
		MatFormFieldModule,
		MatButtonModule,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
		EditorModule,
		MatSlideToggleModule,
		DropdownModule
	],
	declarations: [
		ViewListComponent,
		FormComponent,
		NewUserComponent
	],
	entryComponents: [
		ViewListComponent,
		FormComponent,
		NewUserComponent
	],
	exports: [
	]
})

export class WidgetsComponentModule { }
