import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class EmailService {
    userData: any;
    ccEmail = '';
    url = 'https://boxing-new-backend.herokuapp.com/api/';
    //url = 'http://localhost:3000/api/';
    constructor(private _http: HttpClient, private firestore: AngularFirestore,
    ) {
        let user = JSON.parse(sessionStorage.getItem('user'));
        this.userData = user;
        this.getCCEmail();
    }

    postSendMail(email) {
        const body = JSON.stringify({
            "email": email,
            "ccEmail": this.ccEmail,
        })
        let headers = new HttpHeaders();
        headers = headers.set('content-Type', 'application/json;charset=utf-8');
        headers.set("Access-Control-Allow-Origin", "*");
        return Observable.create((observer) => {
            return this._http.post(this.url + 'send/mail', body, { headers: headers })
                .subscribe(data => {
                    observer.next(data);
                },
                    err => {
                        console.log(err)
                    });
        });
    }


    postUserSendMail(email, subject, description) {
        const body = JSON.stringify({
            "email": email,
            "ccEmail": this.ccEmail,
            "subject": subject,
            "description": description
        })
        let headers = new HttpHeaders();
        headers = headers.set('content-Type', 'application/json;charset=utf-8');
        headers.set("Access-Control-Allow-Origin", "*");
        return Observable.create((observer) => {
            return this._http.post(this.url + 'send/user/mail', body, { headers: headers })
                .subscribe(data => {
                    observer.next(data);
                },
                    err => {
                        observer.next(err)
                    });
        });
    }

    postEnglandUsers(email, password, clubId) {
        const body = JSON.stringify({
            "email": email,
            "password": password,
            "clubId": clubId
        })
        let headers = new HttpHeaders();
        headers = headers.set('content-Type', 'application/json;charset=utf-8');
        headers.set("Access-Control-Allow-Origin", "*");
        return Observable.create((observer) => {
            return this._http.post(this.url + 'all/englandusers', body, { headers: headers })
                .subscribe(data => {
                    observer.next(data);
                },
                    err => {
                        console.log(err)
                    });
        });
    }

    async getCCEmail() {
        this.firestore.collection('users').doc(this.userData.uid).get()
            .subscribe(snapshot => {
                let data = snapshot.data();
                this.ccEmail = data.ccEmail ? data.ccEmail : '';
            });
    }

    postSendCreditMail(email, totalAmount, amount, name) {
        let body = {
            "email": email,
            "totalAmount": totalAmount,
            "amount": amount,
            "name": name,
            "ccEmail": this.ccEmail
        }
        this._http.post(this.url + "wallet/credit", body).toPromise();
    }

}
