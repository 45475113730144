import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { EmailService } from '../../../dashboard/dashboard-v1/service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from "rxjs/operators";
import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf';

@Component({
	selector: 'app-view-list',
	templateUrl: './view-list.component.html',
	styleUrls: ['./view-list.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [EmailService],
})
export class ViewListComponent implements OnInit {
	form: FormGroup;
	otherDetailsForm: FormGroup;
	creditNoteForm: FormGroup;
	data: any;
	title: any;
	titleMessage: any;
	socialIcons: any = [
		{
			link: "https://www.facebook.com",
			icon: "fa-facebook",
			socialIconClass: "btn-fb"
		},
		{
			link: "https://www.twitter.com",
			icon: "fa-twitter",
			socialIconClass: "btn-tw"
		},
		{
			link: "https://www.linkedin.com",
			icon: "fa-linkedin",
			socialIconClass: "btn-li"
		},
		{
			link: "https://www.instagram.com",
			icon: "fa-instagram",
			socialIconClass: "btn-ins"
		}
	]
	cols = [];
	cols1 = [];
	emailTemplate = [];
	loading: boolean;

	personalDetails = false;
	emergencyDetails = false;
	ethinicity = false;
	photographyVideoRecordings = false;
	doctorDetails = false;
	diseaseDetails = false;
	medicalDetails = false;
	disability = false;
	sportingDetails = false;
	signatureDetails = false;
	verificationDate: any;
	isMedical = false;
	selectedImage: any = null;
	images: any[] = [];
	certificate = "";
	reminderDate = "";
	questionnaireData = [];
	ques: any[] = [];
	nameForm: FormGroup;
	coachForm: FormGroup;
	clubList: any[] = [];
	selectedClub = '';
	currentClub = '';
	verificationNotes = '';
	isLoading = false;
	counter = 0;
	signature: any;
	parentsName = '';
	userData: any;

	constructor(public activeModal: NgbActiveModal,
		@Inject(AngularFireStorage) private storage: AngularFireStorage,
		private formBuilder: FormBuilder, private firestore: AngularFirestore,
		public toastrService: ToastrService, private service: EmailService,) {
		let user = JSON.parse(sessionStorage.getItem('user'));
		this.userData = user;
		this.otherDetailsForm = this.formBuilder.group({
			otherDetails: this.formBuilder.array([
				this.getServiceForm()
			])
		});
		this.form = this.formBuilder.group({
			group: new FormControl('', [Validators.required]),
			subject: new FormControl('', [Validators.required]),
			description: new FormControl('', [Validators.required])
		});

		this.nameForm = this.formBuilder.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
		});

		this.coachForm = this.formBuilder.group({
			coachLevel: [''],
			coachExperience: [''],
			coachContent: [''],
		});

		this.creditNoteForm = this.formBuilder.group({
			amount: [''],
			note: [''],
		});

		this.cols = [
			{ field: 'transactionDate', header: 'Date' },
			{ field: 'status', header: 'Status' },
			{ field: 'paymentMode', header: 'Mode' },
			{ field: 'paymentType', header: 'Type' },
			{ field: 'amount', header: 'Amount' },
			{ field: 'openingBalance', header: 'Opening Balance' },
			{ field: 'closingBalance', header: 'Closing Balance' }
		];
		this.cols1 = [
			{ field: 'name', header: 'Name' },
			{ field: 'RFID', header: 'RFID' },
			{ field: 'email', header: 'Email' },
			{ field: 'entryTime', header: 'Entry Time' },
			{ field: 'exitTime', header: 'Exit Time' }
		];
	}

	ngOnInit() {
		this.setEmailData();
		if (this.titleMessage == 'account') {
			this.verificationNotes = '';
			this.signature = '';
			this.firestore.collection('users').doc(this.data.userId).get().subscribe(doc => {
				this.verificationNotes = doc.data().verificationNotes ? doc.data().verificationNotes : '';
				this.parentsName = doc.data().parentsName ? doc.data().parentsName : 'NA';
			});
			if (this.data.medical) {
				if (this.data.medical.diseaseDetails) {
					if (this.data.medical.diseaseDetails.asthma == 'Yes' || this.data.medical.diseaseDetails.chestLungProblems == 'Yes' ||
						this.data.medical.diseaseDetails.diabetes == 'Yes' || this.data.medical.diseaseDetails.epilepsy == 'Yes' ||
						this.data.medical.diseaseDetails.faintingDizziness == 'Yes' || this.data.medical.diseaseDetails.heartProblems == 'Yes' ||
						this.data.medical.diseaseDetails.highBloodPressure == 'Yes')
						this.isMedical = true;
					this.firestore.collection('verification', ref => ref.where('uid', '==', this.data.userId).limit(1)).get()
						.subscribe(snapshot => {
							snapshot.forEach(e => {
								this.personalDetails = e.data().personalDetails;
								this.emergencyDetails = e.data().emergencyDetails;
								this.ethinicity = e.data().ethinicity;
								this.photographyVideoRecordings = e.data().photographyVideoRecordings;
								this.doctorDetails = e.data().doctorDetails;
								this.diseaseDetails = e.data().diseaseDetails;
								this.medicalDetails = e.data().medicalDetails;
								this.disability = e.data().disability;
								this.sportingDetails = e.data().sportingDetails;
								this.signatureDetails = e.data().signatureDetails ? e.data().signatureDetails : false;
								this.verificationDate = e.data().date ? new Date(e.data().date.seconds * 1000) : undefined;
							});
						});
				}
			}
			this.getSignatureData(this.data.userId);
		}
		if (this.titleMessage == 'other') {
			if (this.data.otherDetails) {
				for (let index = 1; index < this.data.otherDetails.otherDetails.length; index++) {
					this.addServiceField();
				}
				this.otherDetailsForm.setValue(this.data.otherDetails)
			}
		}
		if (this.titleMessage == 'questionnaire') {
			this.getQuestionnaireData();
		}
		if (this.titleMessage == 'changeName') {
			this.nameForm.setValue({
				firstName: this.data.userData.firstName,
				lastName: this.data.userData.lastName,
			});
		}
		if (this.titleMessage == 'transferName') {
			if (this.data)
				this.getClubList();
		}
		if (this.titleMessage == 'coach') {
			this.coachForm.setValue({
				coachLevel: this.data.coachLevel ? this.data.coachLevel : '',
				coachExperience: this.data.coachExperience ? this.data.coachExperience : '',
				coachContent: this.data.coachContent ? this.data.coachContent : '',
			});
		}
	}

	getClubList() {
		this.clubList = [];
		this.firestore.collection('clubs').get()
			.subscribe(snapshot => {
				snapshot.forEach(doc => {
					let data = doc.data();
					let c = { label: data.clubDetails.name, value: doc.id };
					if (doc.id != this.data.clubId)
						this.clubList.push(c);
					else
						this.currentClub = data.clubDetails.name;
				});
			});
	}

	transferName(event) {
		this.selectedClub = event;
	}

	async transferNow() {
		if (confirm("Are you sure you want to transfer this user to another club?")) {
			let userRfid = await this.firestore.collection('users', ref => ref.where('clubId', '==', this.selectedClub).where('norfid', '==', this.data.norfid).limit(1)).get().toPromise();
			if (userRfid.empty) {
				this.firestore.doc('users/' + this.data.uid).update({
					clubId: this.selectedClub,
					club_rfid: this.selectedClub + '_' + this.data.norfid,
				})
				this.toastrService.success('User transferred from ' + this.currentClub + ' to ' + this.clubList.filter(a => a.value == this.selectedClub)[0].label, 'Success');
				let user = await this.firestore.collection('users', ref => ref.where('uid', '==', this.data.uid).limit(1)).get().toPromise();
				user.forEach(doc => {
					this.data = doc.data();
					this.getClubList();
					this.selectedClub = '';
				});
			} else {
				this.toastrService.error("RFID already exist", 'Error');
			}
		}
	}

	verifyDetails(userId) {
		if (confirm("Are you sure you want to verified this user details?")) {
			this.firestore.collection("users").doc(userId).update({
				detailsVerified: true,
				verificationNotes: this.verificationNotes
			}).then(result => {
				this.firestore.collection('verification', ref => ref.where('uid', '==', userId).limit(1)).get()
					.subscribe(snapshot => {
						let data = {
							uid: userId, personalDetails: this.personalDetails,
							emergencyDetails: this.emergencyDetails,
							ethinicity: this.ethinicity,
							photographyVideoRecordings: this.photographyVideoRecordings,
							doctorDetails: this.doctorDetails,
							diseaseDetails: this.diseaseDetails,
							medicalDetails: this.medicalDetails,
							disability: this.disability,
							sportingDetails: this.sportingDetails,
							signatureDetails: this.signatureDetails,
							date: new Date()
						}
						if (snapshot.empty) {
							this.firestore.collection('verification').add(data)
						} else {
							snapshot.forEach(e => {
								this.firestore.collection('verification').doc(e.id).update(data)
							});
						}
						this.service.postUserSendMail(this.data.account.personalDetails.email, "Account Details Verified", "<h3>Dear User,</h3><p>Your account details verified.</p><br>Thanks,<br>PamIT")
							.subscribe(data => {
							});
						this.toastrService.success('Details verified', 'Success');
					});
			}).catch(err => {
				this.toastrService.error(err, 'Error');
			});
		}
	}

	async saveForDetails() {
		if (this.titleMessage == 'email') {
			let form = this.form.value;
			this.service.postUserSendMail(form.group, form.subject, form.description)
				.subscribe(data => {
					this.toastrService.success('Email Send.', 'Success')
				});
		} else {
			this.isLoading = true;
			let form = this.form.value;
			for (let i = 0; i < this.data.length; i++) {
				await this.isSendMail(this.data[i].email, form.subject, form.description);
				this.counter += 1;
				if (i == (this.data.length - 1)) {
					this.isLoading = false;
					this.toastrService.success('Email Send.', 'Success');
				}
			}
		}
	}

	async isSendMail(email, subject, description): Promise<any> {
		return new Promise((resolve, reject) => {
			this.service.postUserSendMail(email, subject, description)
				.subscribe(data => {
					resolve('');
				});
		});
	}

	saveOtherDetails() {
		this.saveImageData(1, this.data.uid);
		this.toastrService.success('Data saved successfully!', 'Success');
	}

	getServiceForm(): FormGroup {
		return this.formBuilder.group({
			name: ['', Validators.required],
		});
	}

	addServiceField(): void {
		const control = <FormArray>this.otherDetailsForm.controls.otherDetails;
		control.push(this.getServiceForm());
	}

	removeServiceField(i: number): void {
		const control = <FormArray>this.otherDetailsForm.controls.otherDetails;
		control.removeAt(i);
	}

	showPreview(event: any, type, index) {
		if (event.target.files && event.target.files[0]) {
			let imageData = {
				file: event.target.files[0],
				type: type,
				index: index
			}
			this.images.push(imageData);
		}
	}

	saveImageData(id, userId) {
		this.images.forEach(e => {
			const fileRef = this.storage.ref(id + e.type);
			this.storage.upload(id + e.type, e.file).snapshotChanges().pipe(
				finalize(() => {
					fileRef.getDownloadURL().subscribe((url) => {
						let form = this.otherDetailsForm.value;
						this.certificate = url;
						if (userId != "") {
							this.firestore.doc('users/' + userId).update({
								certificate: this.certificate != '' ? this.certificate : (this.data.certificate ? this.data.certificate : ''),
								reminderDate: this.reminderDate != '' ? this.reminderDate : (this.data.reminderDate ? this.data.reminderDate : ''),
								otherDetails: form
							})
						}
					})
				})
			).subscribe();
		});
	}

	getQuestionnaireData() {
		let index = 0;
		this.firestore.collection('questionnaire', ref => ref.where('clubId', '==', this.data.clubId).orderBy('question', 'asc')).get()
			.subscribe(snapshot => {
				snapshot.forEach(e => {
					let quedata = e.data();
					quedata.id = e.id;
					if (quedata.role == this.data.roles) {
						this.ques[index] = this.data.quastionnaire && this.data.quastionnaire.length > 0 ?
							(this.data.quastionnaire.filter(a => a.question == quedata.question).length > 0 ?
								this.data.quastionnaire.filter(a => a.question == quedata.question)[0].answer : "") : "";
						this.questionnaireData.push(quedata);
						index++;
					}
				});
			});
	}



	getQuestions() {
		let fData = this.questionnaireData.filter(a => a.role == this.data.roles);
		return fData;
	}

	async saveQuestionnaire() {
		let qData = [];
		let fRole = this.questionnaireData.filter(a => a.role == this.data.roles);
		for (let i = 0; i < fRole.length; i++) {
			if (fRole[i].inputType == 'file') {
				let data = {
					question: fRole[i].question,
					answer: await this.saveImageDataInfo(this.data.uid)
				}
				qData.push(data);
			} else if (fRole[i].inputType == 'boolean') {
				let data = {
					question: fRole[i].question,
					answer: this.ques[i] ? (this.ques[i] == '' ? false : true) : false
				}
				qData.push(data);
			} else {
				let data = {
					question: fRole[i].question,
					answer: this.ques[i] ? this.ques[i] : ''
				}
				qData.push(data);
			}
			if (i == (fRole.length - 1)) {
				this.firestore.doc('users/' + this.data.uid).update({ quastionnaire: qData, quastionnaireLastUpdate: new Date() });
				if (this.data.email != '') {
					this.service.postUserSendMail(this.data.email, "Questionnaire Details Added", "<h3>Dear User,</h3><p>Questionnaire details updated in your account.</p><br>Thanks,<br>PamIT")
						.subscribe(data => {
						});
				}
				this.toastrService.success('Data saved successfully!', 'Success');
			}
		}
	}

	showPreviewInfo(event: any, type, index) {
		if (event.target.files && event.target.files[0]) {
			let imageData = {
				file: event.target.files[0],
				type: type,
				index: index
			}
			this.images.push(imageData);
		}
	}

	async saveImageDataInfo(userId): Promise<any> {
		return new Promise((resolve, reject) => {
			this.images.forEach(e => {
				const fileRef = this.storage.ref(e.index + e.type + userId);
				this.storage.upload(e.index + e.type + userId, e.file).snapshotChanges().pipe(
					finalize(() => {
						fileRef.getDownloadURL().subscribe((url) => {
							resolve(url)
						})
					})
				).subscribe();
			})
		});
	}

	saveName() {
		let form = this.nameForm.value;
		if (this.data.account) {
			this.firestore.doc('account/' + this.data.account.accountId).update({
				personalDetails: {
					firstName: form.firstName,
					lastName: form.lastName,
					dob: this.data.account.personalDetails.dob,
					sex: this.data.account.personalDetails.sex,
					address: this.data.account.personalDetails.address,
					postcode: this.data.account.personalDetails.postcode,
					email: this.data.account.personalDetails.email,
					homeTelephoneNo: this.data.account.personalDetails.homeTelephoneNo,
					mobileNo: this.data.account.personalDetails.mobileNo,
					imageUrl: this.data.account.personalDetails.imageUrl,
					volunteer: this.data.account.personalDetails.volunteer
				},
				emergencyDetails: {
					emergencyContactName: this.data.account.emergencyDetails.emergencyContactName,
					emergencyRelationship: this.data.account.emergencyDetails.emergencyRelationship,
					emergencyContactAddress: this.data.account.emergencyDetails.emergencyContactAddress,
					emergencyContactNo: this.data.account.emergencyDetails.emergencyContactNo
				},
				updatedDate: new Date()
			})
		}
		this.firestore.doc('users/' + this.data.userId).update({
			firstName: form.firstName,
			lastName: form.lastName,
			accountDetailsUpdatedDate: new Date()
		})
		this.service.postUserSendMail(this.data.account.personalDetails.email, "Name Change", "<h3>Dear User,</h3><p>Your name is updated in your account.</p><br>Thanks,<br>PamIT")
			.subscribe(data => {
			});
		this.toastrService.success('Data saved successfully!', 'Success');
	}

	saveCoachDetails() {
		let form = this.coachForm.value;
		this.firestore.doc('users/' + this.data.uid).update({
			coachLevel: form.coachLevel,
			coachExperience: form.coachExperience,
			coachContent: form.coachContent
		}).then(res => {
			this.toastrService.success('Data saved successfully!', 'Success');
		}).catch(error => {
			this.toastrService.error(error, "Error")
		})
	}

	async saveCreditNote() {
		let form = this.creditNoteForm.value;
		if (form.amount <= 0) {
			this.toastrService.error("Please enter valid amount", "Error")
		} else if (form.note <= 0) {
			this.toastrService.error("Please enter note", "Error")
		} else {
			if (confirm("Are you sure you want to credit " + form.amount + " amount this user?")) {
				let userData = (await this.firestore.doc('users/' + this.data.uid).get().toPromise()).data();
				let totalAmount = userData.balance + form.amount;
				this.firestore.collection("transactions").add({
					uid: userData.uid,
					name: userData.firstName + ' ' + userData.lastName,
					email: userData.email,
					RFID: userData.RFID,
					transactionDate: new Date(),
					stripeToken: '',
					paymentMode: 'card',
					paymentType: 'credit',
					status: 'success',
					amount: form.amount,
					openingBalance: userData.balance,
					closingBalance: totalAmount,
					clubId: userData.clubId,
					sessionId: '',
					orderId: '',
					details: form.note
				});
				this.firestore.doc('users/' + this.data.uid).update({
					balance: totalAmount,
				}).then(res => {
					this.service.postSendCreditMail(userData.email, totalAmount, form.amount, userData.firstName + ' ' + userData.lastName);
					this.toastrService.success('Amount credited successfully!', 'Success');
				}).catch(error => {
					this.toastrService.error(error, "Error")
				})
			}
		}
	}

	public captureScreen() {
		let filename = 'User Details' + '_' + new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear();
		const doc = new jsPDF()
		autoTable(doc, {
			html: '#contentToConvert',
			willDrawCell: function (data) {
				if (data.cell.text[0] == 'Personal Details' || data.cell.text[0] == 'Emergency Details' ||
					data.cell.text[0] == 'Your Ethnicity' || data.cell.text[0] == 'Photography And Video Recordings' ||
					data.cell.text[0] == 'Doctor Details' || data.cell.text[0] == 'Disease Details' ||
					data.cell.text[0] == 'Disability' || data.cell.text[0] == 'Medical Details' || data.cell.text[0] == 'Sporting Details') {
					doc.setFillColor(211, 211, 211);
				}
			},
		})
		doc.save(filename + '.pdf')
	}

	getSignatureData(userId) {
		this.firestore.collection('signature', ref => ref.where('userId', '==', userId)).get()
			.subscribe(snapshot => {
				snapshot.forEach(doc => {
					this.signature = doc.data();
				});
			});
	}

	getEmailTemplateData() {
		this.emailTemplate = [];
		this.firestore.collection('emailTemplate', ref => ref.where('clubId', '==', this.userData.clubId)).get()
			.subscribe(snapshot => {
				snapshot.forEach(doc => {
					let res = doc.data();
					res.id = doc.id;
					this.emailTemplate.push(res);
				});
			});
	}

	templateChange(event) {
		if (event != '') {
			let data = this.emailTemplate.filter(a => a.name == event)[0];
			if (this.titleMessage == 'email') {
				this.form.setValue({
					group: this.data.email,
					subject: data.name,
					description: data.description
				});
			}
			if (this.titleMessage == 'multiEmail') {
				let email = '';
				this.data.forEach(e => {
					email = email + ',' + e.email;
				});
				this.form.setValue({
					group: email,
					subject: data.name,
					description: data.description
				});
			}
		} else {
			this.setEmailData();
		}
	}

	setEmailData() {
		this.getEmailTemplateData();
		if (this.titleMessage == 'email') {
			this.form.setValue({
				group: this.data.email,
				subject: '',
				description: '',
			});
		}
		if (this.titleMessage == 'multiEmail') {
			let email = '';
			this.data.forEach(e => {
				email = email + ',' + e.email;
			});
			this.form.setValue({
				group: email,
				subject: '',
				description: '',
			});
		}
	}
}
