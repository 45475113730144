import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
    providedIn: 'root'
})

export class ClubService {

    constructor(private firestore: AngularFirestore) { }

    addClub(first, second, third, forth, fifth, sixth,seventh) {
        let data = {
            clubDetails: first,
            contactDetails: second,
            englandClubFormDetails: third,
            planDetails: forth,
            services: fifth,
            groupType: sixth,
            socialMedia: seventh,
            createdDate: new Date(),
            updatedDate: null,
            isActive: true,
            password: '',
            userId:''
        }
        return this.firestore.collection('clubs').add(data);
    }

    updateClub(first, second, third, forth, fifth, sixth,seventh, docid) {
        let data = {
            clubDetails: first,
            contactDetails: second,
            englandClubFormDetails: third,
            planDetails: forth,
            services: fifth,
            groupType: sixth,
            socialMedia: seventh,
            updatedDate: new Date(),
            isActive: true
        }
        return this.firestore.doc('clubs/' + docid).update(data)
    }


}