import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-emailverify',
  templateUrl: './emailverify.component.html',
  styleUrls: ['./emailverify.component.scss'],
})
export class EmailverifyComponent implements OnInit {

  constructor(public authService: AuthService,) { }

  ngOnInit() {
  }

}
