import { Routes } from '@angular/router';

import { LoginoneComponent } from './loginone/loginone.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LockScreenComponent } from './lockscreen/lockscreen.component';
import { RegisterClubComponent } from './register-club/club.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { EmailverifyComponent } from './emailverify/emailverify.component';

export const SessionRoutes: Routes = [{
  path: '',
  redirectTo: 'loginone',
  pathMatch: 'full',
}, {
  path: 'loginone',
  component: LoginoneComponent
},
// {
//   path: 'register',
//   component: RegisterComponent
// }, 
{
  path: 'register-new-club',
  component: RegisterClubComponent
},
{
  path: 'forgot-password',
  component: ForgotPasswordComponent
}, {
  path: 'lockscreen',
  component: LockScreenComponent
}, {
  path: 'register-success/:uid',
  component: RegisterSuccessComponent
}, {
  path: 'verify-email',
  component: EmailverifyComponent
}];
