import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../service/auth/auth.service';

@Component({
   selector: 'ms-loginone-session',
   templateUrl: './loginone-component.html',
   styleUrls: ['./loginone-component.scss'],
   encapsulation: ViewEncapsulation.None,
})

export class LoginoneComponent {

   email: string = "";
   password: string = "";

   constructor(private router: Router,
      private toastr: ToastrService,
      private authService: AuthService,
      public translate: TranslateService) {
         sessionStorage.clear();
         localStorage.clear();
       }

   logIn(value) {
      this.authService.loginUser(value);
   }

}



