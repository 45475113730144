import { Component, OnInit } from '@angular/core';
import { ToastrService, ToastrConfig } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent implements OnInit {
  regData:any;
  constructor(private firestore: AngularFirestore, public translate: TranslateService,
    public toastrService: ToastrService, private route: ActivatedRoute,) {
    this.firestore.collection('users', ref => ref.where('uid', '==', this.route.snapshot.paramMap.get('uid'))).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          this.regData = doc.data();
        });
      });
  }

  ngOnInit() {
  }

}
