import { Injectable } from '@angular/core';
export interface ChildrenItems {
   state: string;
   name: string;
   type?: string;
   label?: string;
}

export interface Menu {
   state: string;
   name: string;
   type: string;
   icon: string;
   label?: string;
   children?: ChildrenItems[];
}

const MENUITEMS = [
   {
      state: 'home',
      name: 'HOME',
      type: 'link',
      icon: 'icon-home icons',
      title: 'Show overall analytics of club details'
   },
   {
      state: 'user-list',
      name: 'USERS LIST',
      type: 'link',
      icon: 'icon-people icons',
      title: 'Show all users details of club'
   },
   {
      state: 'sessions',
      name: 'SESSIONS LIST',
      type: 'link',
      icon: 'icon-event icons',
      title: 'Show all sessions list of club'
   },
   {
      state: 'club-profile',
      name: 'CLUB PROFILE',
      type: 'link',
      icon: 'icon-organization icons',
      title: 'Show profile and settings of club'
   },
   {
      state: 'order-history',
      name: 'SALES HISTORY',
      icon: 'icon-chart  icons',
      type: 'link',
      title: 'Show all sales history of club'
   },
   {
      state: 'products',
      name: 'PRODUCTS FOR SALE',
      icon: 'icon-bag icons',
      type: 'link',
      title: 'Show all products, brands and categories of club'
   },
   {
      state: 'club-list',
      name: 'CLUB LIST',
      icon: 'icon-grid icons',
      type: 'link',
      title: 'Show all clubs details'
   },
   {
      state: 'finance',
      name: 'FINANCE',
      type: 'link',
      icon: 'icon-notebook icons',
      title: 'Finance show weekly transactions analysis of cash/card payments of club'
   },
   {
      state: 'transactions',
      name: 'USER TRANSACTIONS',
      type: 'link',
      icon: 'icon-wallet icons',
      title: 'Show all transactions of users'
   },
   {
      state: 'user-logs',
      name: 'USER HISTORY',
      type: 'link',
      icon: 'icon-grid icons',
      title: 'Show all logs details of club users'
   },
   {
      state: 'about-us',
      name: 'ABOUT US',
      type: 'link',
      icon: 'icon-menu icons',
      title: 'Change details of frontside of club'
   },
   {
      state: 'plan',
      name: 'SESSION PLANS',
      type: 'link',
      icon: 'icon-credit-card icons',
      title: 'Show sessions plans of club'
   },
   // {
   //    state: 'in-out',
   //    name: 'IN-OUT',
   //    icon: 'icon-clock  icons',
   //    type: 'link',
   //    title: 'Change details of frontside of club'
   // },
   // {
   //    state: 'club-form',
   //    name: 'CLUB DETAILS',
   //    type: 'link',
   //    icon: 'icon-event icons',
   // },
   // {
   //    state: 'import-user',
   //    name: 'UPLOAD USER DATA ',
   //    type: 'link',
   //    icon: 'icon-cloud-upload icons',
   // },
   // {
   //    state: 'import-user-details',
   //    name: 'UPLOAD USER DETAILS ',
   //    type: 'link',
   //    icon: 'icon-cloud-upload icons',
   // },
   {
      state: 'email',
      name: 'EMAIL',
      type: 'link',
      icon: 'icon-envelope icons',
      title: 'Email section'
   },
   {
      state: 'user-roles',
      name: 'USER ROLES',
      type: 'link',
      icon: 'icon-user icons',
      title: 'Define user roles'
   },
   {
      state: 'add-club',
      name: 'ADD NEW CLUB',
      icon: 'icon-settings icons',
      type: 'link',
      title: 'Add new club and fill details'
   }
];

@Injectable()
export class MenuItems {

   add(menu: any) {
      MENUITEMS.push(menu);
   }

   getAll(): Menu[] {
      let user = JSON.parse(sessionStorage.getItem('user'));
      if (user) {
         if (user.roles == 'superAdmin')
            return MENUITEMS.filter(a => a.state != 'plan' && a.state != 'sessions' && a.state != 'club-form'
               && a.state != 'import-user' && a.state != 'import-user-details' && a.state != 'email'
               && a.state != 'add-club' && a.state != 'in-out' && a.state != 'products'
               && a.state != 'order-history');
         else
            return MENUITEMS.filter(a => a.state != 'add-club' && a.state != 'club-list'
               && a.state != 'about-us' && a.state != 'user-roles');
      } else
         return [];
   }

   getUserName() {
      let user = JSON.parse(sessionStorage.getItem('user'));
      return user ? (user.name ? user.name : user.firstName + ' ' + user.lastName) : '';
   }

   getUserImage() {
      let user = JSON.parse(sessionStorage.getItem('user'));
      return user ? user.imageUrl : '';
   }
}
