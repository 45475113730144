import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
	selector: 'app-request-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
	data: any;
	title: any;
	titleMessage: any;
	cols = [];
	loading: boolean;
	todayDate = new Date()
	formData: any[] = [];
	userData: any;
	clubData: any;

	constructor(public activeModal: NgbActiveModal, private firestore: AngularFirestore) {
		let user = JSON.parse(sessionStorage.getItem('user'));
		this.userData = user;
		this.firestore.collection('clubs').doc(this.userData.clubId).get()
			.subscribe(snapshot => {
				this.clubData = snapshot.data();
				this.formData.push(snapshot.data().englandClubFormDetails);
			});
	}

	ngOnInit() {
		var body = document.body;
		body.classList.add("contact-modal");
	}

	public captureScreen() {
		let filename = this.data.personalDetails.firstName + '_' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear();
		var data = document.getElementById('contentToConvert');
		html2canvas(data).then(canvas => {
			// Few necessary setting options  
			var imgWidth = 208;
			var pageHeight = 295;
			var imgHeight = canvas.height * imgWidth / canvas.width;
			var heightLeft = imgHeight;

			const contentDataURL = canvas.toDataURL('image/jpeg')
			let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
			var position = 10;
			pdf.addImage(contentDataURL, 'jpeg', 0, position, imgWidth, imgHeight)
			pdf.save(filename + '.pdf'); // Generated PDF   
		});
	}

	ngOnDestroy() {
		var body = document.body;
		body.classList.remove("contact-modal");
	}

}
