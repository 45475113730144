import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from './user';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
@Injectable({
   providedIn: 'root'
})
export class AuthService {
   user$: any;
   user: Observable<firebase.User>;
   userData: any;
   isLoggedIn = false;

   constructor(private firebaseAuth: AngularFireAuth, public afs: AngularFirestore, private firestore: AngularFirestore,
      private router: Router,
      private toastr: ToastrService) {
      this.user = firebaseAuth.authState;
   }

   getLocalStorageUser() {
      this.userData = JSON.parse(sessionStorage.getItem("user"));
      if (this.userData && this.userData.email) {
         this.isLoggedIn = true;
         return true;
      } else {
         this.isLoggedIn = false;
         return false;
      }
   }

   signupUserProfile(value) {
      this.firebaseAuth
         .auth
         .createUserWithEmailAndPassword(value.email, value.password)
         .then(value => {
            this.setLocalUserProfile(value);
            this.router.navigate(['/']);
            this.firebaseAuth.auth.currentUser.sendEmailVerification()
               .then(() => {
                  this.toastr.success('Account Created! Verfication email send, check your inbox.', 'Success');
               }).catch((error) => {
                  this.toastr.error(error.message, 'Error');
               })
         })
         .catch(err => {
            this.toastr.error(err.message);
         });
   }

   signUp(email, name, image, clubId, type) {
      let dyPassword = this.generatePassword();
      let pass = dyPassword;

      return this.firebaseAuth.auth.createUserWithEmailAndPassword(email, pass)
         .then((result) => {
            this.SetUserData(result.user, pass, name, image, clubId);
            this.clubUpdate(clubId, pass, result.user.uid);
            this.toastr.success('Successfully registered', 'Success');
            this.afs.collection("wallet").add({
               uid: result.user.uid,
               balance: 0,
               currency: "GBP"
            });
            this.SendVerificationMail();
            if (type == 'register') {
               this.firebaseAuth.auth.currentUser.sendEmailVerification()
                  .then(() => {
                     this.toastr.success('Account Created! Verfication email send, check your inbox.', 'Success');
                     this.router.navigate(['/session/register-success/', result.user.uid]);
                  }).catch((error) => {
                     this.toastr.error(error.message, 'Error');
                  })
            } else
               this.SendVerificationMail();
         }).catch((error) => {
            this.toastr.error(error.message, 'Error');
         })
   }

   clubSignUp(email, name) {
      let dyPassword = this.generatePassword();
      let pass = dyPassword;

      return this.firebaseAuth.auth.createUserWithEmailAndPassword(email, pass)
         .then((result) => {
            //this.SendVerificationMail();
            this.SetClubAdminData(result.user, pass, name);
            this.toastr.success('Successfully registered', 'Success');
            this.afs.collection("wallet").add({
               uid: result.user.uid,
               balance: 0,
               currency: "GBP"
            });
            return [{ data: result.user, password: pass }];
         }).catch((error) => {
            this.toastr.error(error.message, 'Error');
            return [];
         })
   }

   SignUpUser(email, password, firstName, lastName, role, title) {
      let user = JSON.parse(sessionStorage.getItem('user'));
      if (!firstName)
         this.toastr.error('First name is required', 'Error');
      else if (!lastName)
         this.toastr.error('Last name is required', 'Error');
      else if (!email)
         this.toastr.error('Email is required', 'Error');
      else if (!password)
         this.toastr.error('Password is required', 'Error');
      else {
         return this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password)
            .then((result) => {
               this.afs.collection('clubs').doc(user.clubId).get()
                  .subscribe(clubResult => {
                     this.afs.collection('users', ref => ref.where('clubId', '==', user.clubId).limit(1).orderBy('norfid', 'desc')).get()
                        .subscribe(snapshot => {
                           if (snapshot.empty) {
                              let userR = {
                                 RFID: 0
                              }
                              this.SetClubUserData(result.user, firstName, lastName, user.clubId, role, clubResult.data(), userR, title);
                           } else {
                              snapshot.forEach(doc1 => {
                                 this.SetClubUserData(result.user, firstName, lastName, user.clubId, role, clubResult.data(), doc1.data(), title);
                              });
                           }
                        });
                  });
               this.afs.collection("wallet").add({
                  uid: result.user.uid,
                  balance: 0,
                  currency: "GBP",
                  clubId: user.clubId
               });
               this.firebaseAuth.auth.currentUser.sendEmailVerification()
                  .then(() => {
                     this.toastr.success('Account Created! Verfication email send, check your inbox.', 'Success');
                  }).catch((error) => {
                     this.toastr.error(error.message, 'Error');
                  })
            }).catch((error) => {
               this.toastr.error(error.message, 'Error');
            })
      }
   }

   generatePassword() {
      var length = 10,
         charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
         retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
         retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
   }

   SendVerificationMail() {
      return this.firebaseAuth.auth.currentUser.sendEmailVerification()
         .then(() => {
            this.toastr.success('Verfication email send, check your inbox.', 'Success');
         }).catch((error) => {
            this.toastr.error(error.message, 'Error');
         })
   }

   loginUser(value) {
      this.firebaseAuth
         .auth
         .signInWithEmailAndPassword(value.email, value.password)
         .then(value => {
            this.afs.collection('users').doc(value.user.uid).get()
               .subscribe(snapshot => {
                  if (snapshot.exists) {
                     let userData = snapshot.data();
                     if (userData.roles == "clubAdmin" || userData.roles == "superAdmin" || userData.roles == "admin") {
                        this.setLocalUserProfile(value);
                        if (this.firebaseAuth.auth.currentUser.emailVerified) {
                           if (!userData.emailVerified) {
                              this.afs.collection('users').doc(value.user.uid).set({ "emailVerified": true }, {
                                 merge: true
                              });
                           }
                           sessionStorage.setItem('user', JSON.stringify(userData));
                           this.getClubData(userData);
                           this.router.navigate(['/']);
                           this.toastr.success('You have been successfully logged In!');
                        } else {
                           this.toastr.error("Email not verified. We have sent a confirmation email to verify your email.", 'Error');
                           this.SendVerificationMail();
                        }
                     } else {
                        this.toastr.error("Access denied", 'Error');
                     }
                  } else {
                     this.toastr.error("User not found", 'Access denied');
                  }
               });
         })
         .catch(err => {
            this.toastr.error(err.message);
         });
   }

   async getClubData(user) {
      let club = await this.firestore.collection("clubs").doc(user.clubId).get().toPromise();
      sessionStorage.setItem('clubName', club.data().clubDetails.name as string);
   }

   resetPassword(value) {
      this.firebaseAuth.auth.sendPasswordResetEmail(value.email)
         .then(value => {
            this.toastr.success("Email Sent");
            this.router.navigate(['/session/loginone']);
         })
         .catch(err => {
            this.toastr.error(err.message);
         });
   }

   logOut() {
      return this.firebaseAuth.auth.signOut().then(() => {
         localStorage.clear();
         sessionStorage.clear();
         this.isLoggedIn = false;
         this.toastr.success("You have been successfully logged out!");
         this.router.navigate(['/session/loginone']);
      })
   }

   setLocalUserProfile(value) {
      localStorage.setItem("userProfile", JSON.stringify(value));
      this.isLoggedIn = true;
   }

   SetUserData(user, password, name, photoURL, clubId) {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const userData: User = {
         clubId: clubId,
         uid: user.uid,
         email: user.email,
         name: name,
         photoURL: photoURL,
         emailVerified: user.emailVerified,
         balance: 0,
         currency: 'GBP',
         isDelete: false,
         password: password,
         isActive: true,
         roles: 'clubAdmin',
         createdDate: new Date(),
         updatedDate: null,
         deletedDate: null
      }
      return userRef.set(userData, {
         merge: true
      })
   }

   SetClubAdminData(user, password, name) {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const userData: User = {
         clubId: "",
         uid: user.uid,
         email: user.email,
         name: name,
         photoURL: "",
         emailVerified: user.emailVerified,
         balance: 0,
         currency: 'GBP',
         isDelete: false,
         password: password,
         isActive: true,
         roles: 'clubAdmin',
         createdDate: new Date(),
         updatedDate: null,
         deletedDate: null
      }
      return userRef.set(userData, {
         merge: true
      })
   }

   SetClubUserData(user, firstName, lastName, clubId, role, clubdata, userR, title) {
      let club = clubdata.planDetails.planDetails.filter(a => a.plan == "Incomplete");
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      this.afs.collection("users", ref => ref.where('clubId', '==', clubId)).get().subscribe(snapshot => {
         const userData = {
            uid: user.uid,
            email: user.email,
            firstName: firstName,
            lastName: lastName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
            roles: role,
            clubId: clubId,
            RFID: (snapshot.size + 1) + '',
            norfid: snapshot.size + 1,
            group: club.length > 0 ? club[0].plan : clubdata.planDetails.planDetails[0].plan,
            groupType: clubdata.groupType.groupType[0].name,
            club_rfid: clubId + '_' + (snapshot.size + 1),
            balance: 0,
            isDelete: false,
            isActive: false,
            accountDetailsAddedDate: null,
            accountDetailsUpdatedDate: null,
            medicalDetailsAddedDate: null,
            medicalDetailsUpdatedDate: null,
            membershipDetailsAddedDate: null,
            membershipDetailsUpdatedDate: null,
            createdDate: new Date(),
            deletedDate: null
         }
         return userRef.set(userData, {
            merge: true
         })
      });

   }

   clubUpdate(clubId, password, userId) {
      this.afs.doc('clubs/' + clubId).update({
         password: password,
         userId: userId
      })
   }
}
