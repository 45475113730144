import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../../service/auth/auth.service';

@Component({
	selector: 'app-new-user',
	templateUrl: './newUser.component.html',
	styleUrls: ['./newUser.component.scss'],
})
export class NewUserComponent implements OnInit {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	title: string;
	role: string = "clubUser";
	filterRoles = [];
	titleData = [{ name: 'Mr.', value: 'Mr.' }, { name: 'Mrs.', value: 'Mrs.' }, { name: 'Miss', value: 'Miss' },
	{ name: 'Ms.', value: 'Ms.' }, { name: 'Dr.', value: 'Dr.' }, { name: 'Prof.', value: 'Prof.' },
	{ name: 'Rev.', value: 'Rev.' }]

	constructor(public activeModal: NgbActiveModal, private firestore: AngularFirestore,
		public service: AuthService) {
		this.firestore.collection('roles').get()
			.subscribe(snapshot => {
				snapshot.forEach(doc => {
					this.filterRoles.push(doc.data())
				});
			});
	}

	ngOnInit() {
	}



}
